import React, {useEffect, useState}  from "react";
import { Link } from 'gatsby';
import { Button, Hero, ExternalIcon } from "components/ui";
import { WhatsNew, MoreResources, CurrentTopics, LatestWebinars, AboutNCSACW } from "components/containers/Homepage/index";
import HomepageHeroDesktop from "assets/images/hero-home-1200x426-ICWA-toolkit.jpg";
import HomepageHeroTablet from "assets/images/hero-home-tablet-1040x425-ICWA-toolkit.jpg";
import HomepageHeroDesktopSept2024 from "assets/images/hero-home-1200x426-sep2024.jpg";
import HomepageHeroTabletSept2024 from "assets/images/hero-home-tablet-1040x425-sep2024.jpg";
import { DateTime } from "luxon";

// const heroContent={
//   title: "Safety & Risk Practice Guides",
//   link: "topics/safety-and-risk/safety-risk-guides-series"
// }
const heroContent={
  title: "ICWA Active Efforts Support Toolkit",
  link: "topics/american-indian-alaska-native/ICWA-active-efforts-series"
}
const heroContentSept2024={
  title: "National Recovery Month"
}

const Home = () => {
  const [currentDate, setCurrentDate] = useState(false);
  const timeZone = "America/New_York"
  const year = 2024;
  const month = 10;
  const day = 1;
  const hour = 0;
  const minute = 0;
  const updateHeroDate = DateTime.fromObject({year:year,month:month,day:day,hour:hour,minute:minute},{zone:timeZone});

  useEffect(()=>{
    setCurrentDate(DateTime.now().setZone(timeZone));
  }, [])
  return(
    <>
        <Hero
        desktopImg={currentDate > updateHeroDate ? HomepageHeroDesktop:HomepageHeroDesktopSept2024}
        tabletImg={currentDate > updateHeroDate ? HomepageHeroTablet:HomepageHeroTabletSept2024}
        color={"#363B50"}
        textColor="#fff"
        headingColor="#fff"
        isHomeHero={true}
        >
          <h1 name="main-content" id="main-content" className="usa-hero__heading" aria-label={`Hero: ${currentDate > updateHeroDate ? heroContent.title : heroContentSept2024.title}`}>{currentDate > updateHeroDate ? heroContent.title : heroContentSept2024.title}</h1>
          {currentDate > updateHeroDate ? <p>Helps child welfare workers, substance use treatment staff, court personnel, attorneys, and healthcare professionals support American Indian and Alaskan Native (AI/AN) families affected by substance use</p>:<p>Recovery is possible! We offer tailored <Link to="/training">training</Link>, <Link to="/topics">resources</Link>, and <Link to="/technical">technical assistance</Link> to help providers effectively support families and promote recovery and well-being.</p>}
          {currentDate > updateHeroDate &&
            <Button
            color="usa-button--secondary"
            labelText={`Open ${heroContent.title} page`}
            link={heroContent.link}
            >
              View Series
            </Button>
          }
        </Hero>
        <div className="light-gray-bg">
          <div className="grid-container">
            <div className="grid-row">
              <WhatsNew />
              <LatestWebinars />
            </div>
          </div>
        </div>
        <AboutNCSACW />
        <div className="blue-bg padding-y-5">
          <CurrentTopics />
        </div>
      <MoreResources />
    </>
  )
}

export default Home;
