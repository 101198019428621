import React from "react";
import {Card, Button} from "components/ui";
const currentTopicsData = [
    {id: 1,
    title: "Medication for Substance Use Disorders",
    description: "<p>Evidence-based, clinically driven, and individually tailored to successfully treat SUDs and help sustain recovery.</p>",
    link: "/topics/medication-for-substance-use-disorders"
    },
    {id: 2,
    title: "Fentanyl",
    description: "<p>Fentanyl-related deaths are on the rise and affecting many children and families in the United States.</p>",
    link: "/topics/fentanyl"
    },
    {id: 3,
    title: "CAPTA Plans of Safe Care",
    description: "<p>A Plan of Safe Care is a tool multiple systems can use to help infants affected by prenatal substance exposure and their families.</p>",
    link: "/topics/capta-plans-of-safe-care"
    },
    {id: 4,
    title: "Advancing Equity in Access to Care",
    description: "<p>Families of color and other diverse groups affected by child abuse or neglect and SUDs often face a complex set of challenges.</p>",
    link: "/topics/advancing-equity"
    }
  ];
const CurrentTopics = () => {
    return (
        <div className="grid-container">
          <h2 className="text-base-lightest">Trending Topics</h2>
          <div className="usa-card-group">
            {currentTopicsData.map(resource=>{
                return ( 
                <Card grid="desktop:grid-col-3 tablet:grid-col-6"
                key={`current-topic-${resource.id}`}>
                <Card.Header>
                  <h2 className="usa-card__heading">{resource.title.substring(0,56)}{resource.title.length > 56 && <span>...</span>}</h2>
                </Card.Header>
                <Card.Body>
                <div
                  dangerouslySetInnerHTML={{
                  __html: resource?.description,
                  }}
                  className="unordered-list-style"
                />
                </Card.Body>
                <Card.Footer cardFooter="text-center">
                  <Button
                    btnType="usa-button"
                    link={resource.link}
                    color="primary-button"
                    size="padding-x-5"
                    labelText={`Learn more about ${resource.title}`}
                    >
                      Learn More
                  </Button>
                </Card.Footer>
              </Card>
              )})}
        </div>
     </div>
    )
}

export default CurrentTopics;