import React from 'react';
import Layout from 'components/layout';
import SeoComponent from 'components/seo';
import Home from 'components/containers/Homepage/Home';
import { useStrapiHomePageData } from 'hooks';

const IndexPage = ({ location }) => {
    const { strapiHome } = useStrapiHomePageData();
    const seo = {
        metaTitle: strapiHome?.title,
        metaDescription: strapiHome?.description,
        metaKeywords: strapiHome?.keywords
    }
return <Layout location={location}>
    <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords} />
    <Home />
</Layout>
};

export default IndexPage;
