import React from "react";
import {Link} from "gatsby";
import {FlagCard} from "components/ui";
import BibliographyIcon from "assets/images/icon-home-bibliography.png";
import StatisticsIcon from "assets/images/icon-home-statistics.png";
import TutorialsIcon from "assets/images/icon-home-tutorial.png";
import VideosIcon from "assets/images/icon-home-videos.png";

const moreResourcesData = [
    {id: 1,
    title: "Online Tutorials",
    description: "Access free online tutorials for substance use treatment, child welfare, and court professionals and a comprehensive training package on substance use disorders for child welfare professionals.",
    background: "light-gray-bg",
    link: "https://ncsacw.acf.hhs.gov/tutorials/Default.aspx"
    },
    {id: 2,
    title: "Interactive Bibliography",
    description: "The Interactive Bibliography is a searchable platform for individual citations identified in the field of cross-system issues involving child welfare, substance use disorders, and family treatment and dependency courts.",
    background: "red-bg",
    link: "/research/bibliography"
    },
    {id: 3,
    title: "Video and Webinars",
    description: "View engaging videos and recorded webinars on an array of topics related to serving families affected by substance use disorders and involvement with child welfare services.",
    background: "green-bg",
    link: "/training/videos-and-webinars"
    },
    {id: 4,
    title: "Statistics",
    description: "Stay up to date with the latest national data and research literature on substance use treatment and child welfare.",
    background: "light-gray-bg",
    link: "/research/child-welfare-statistics"
    }
  ];
const MoreResources = () => {
    return (
        <div className="grid-container margin-y-5">
        <h2>More Resources</h2>
        <div className="usa-card-group">
          <div className="grid-row grid-gap padding-left-5">
            {moreResourcesData.map(resource=>{
              return (
                <FlagCard 
                key={`Resource-${resource.id}`}
                background={resource.background}
                roundMedia={true}
                grid="tablet:grid-col-6">
                  <FlagCard.Header>
                    <h2 className="usa-card__heading">{resource.title}</h2>
                  </FlagCard.Header>
                  <FlagCard.Media>
                    <div className="usa-card__img">
                    {resource.id === 1 &&
                     <img
                        src={TutorialsIcon}
                        alt=""
                        />
                    }
                    {resource.id === 2 &&
                     <img
                        src={BibliographyIcon}
                        alt=""
                        />
                    }
                    {resource.id === 3 &&
                     <img
                        src={VideosIcon}
                        alt=""
                        />
                    }
                    {resource.id === 4 &&
                     <img
                        src={StatisticsIcon}
                        alt=""
                        />
                    }
                    </div>
                  </FlagCard.Media>
                 <FlagCard.Body>
                    <p>{resource.description}</p>
                  </FlagCard.Body>
                  <FlagCard.Footer>
                  <Link
                    to={resource.link}
                    className="font-weight-bold"
                    aria-label={`Learn more about ${resource.title}`}
                    >
                      Learn More
                  </Link>
                  </FlagCard.Footer>
               </FlagCard>
              )})}
          </div>
       </div>
    </div>
    )
}

export default MoreResources;
