import React from "react";
import {Button} from "components/ui";
import AboutImage from "assets/images/childhood-and-adolescents-approach.jpeg";
import styled from "styled-components";

const AboutNCSACW = () => {
    const AboutWrapper = styled.div`
    .round-image{
        border-radius: 50%;
        overflow: hidden;
        @media (max-width: 55rem) {
            width: 200px;
            height: 200px;  
        }
        width: 250px;
        height: 250px;
        img{
            width:100%;
            height:100%;
            object-fit: cover;
        }
    }
    p{
        line-height: 1.7rem;
    }
    @media (max-width: 40rem){
        .view-more{
            text-align: center;
        } 
    }
`;
    return (
        <AboutWrapper className="grid-container margin-y-5">
        <div className="grid-row">
          <div className="tablet-lg:grid-col-1"></div>
          <div className="grid-col-auto round-image margin-x-auto ">
            <img src={AboutImage} alt=""/>
          </div>
          <div className="tablet:grid-col-7 desktop:margin-left-4 mobile-lg:grid-col-12 mobile-lg:margin-x-auto">
              <h2>About NCSACW</h2>
              <p className="">NCSACW is a national resource center providing information, expert consultation, training and technical assistance to child welfare, dependency court and substance use treatment professionals to improve the safety, permanency, well-being and recovery outcomes for children, parents, and families.</p>
              <div className="view-more">
                <Button
                link="/about-us"
                color="primary-button"
                size="padding-x-5"
                labelText="Learn more about NCSACW"
                >
                  Learn More
                </Button>
            </div>
          </div>
          <div className="tablet-lg:grid-col-1"></div>
        </div>
      </AboutWrapper>
    )
}

export default AboutNCSACW;